import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 1280.000000 1273.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1273.000000) scale(0.100000,-0.100000)">

<path d="M3197 12723 c1762 -2 4644 -2 6405 0 1762 1 321 2 -3202 2 -3523 0
-4964 -1 -3203 -2z"/>
<path d="M6120 12173 c-376 -25 -617 -55 -875 -108 -38 -8 -99 -20 -135 -27
-36 -7 -67 -15 -70 -19 -3 -3 -25 -9 -50 -12 -25 -4 -69 -13 -97 -22 -67 -20
-149 -44 -188 -55 -47 -13 -325 -108 -370 -126 -22 -9 -54 -23 -72 -30 -17 -8
-37 -14 -43 -14 -7 0 -45 -15 -84 -33 -39 -19 -96 -44 -126 -57 -62 -25 -354
-168 -415 -203 -51 -28 -155 -89 -176 -102 -9 -5 -34 -20 -55 -33 -40 -23 -70
-42 -88 -54 -6 -5 -60 -40 -121 -79 -60 -39 -111 -76 -113 -80 -2 -5 -9 -9
-15 -9 -7 0 -26 -14 -44 -30 -18 -17 -35 -30 -39 -30 -5 0 -81 -56 -94 -70 -3
-3 -32 -25 -65 -50 -33 -25 -76 -59 -95 -77 -19 -17 -56 -48 -82 -68 -27 -20
-48 -39 -48 -43 0 -4 -12 -15 -28 -24 -28 -18 -69 -56 -232 -213 -166 -162
-327 -338 -455 -500 -22 -27 -42 -52 -45 -55 -5 -4 -45 -56 -82 -105 -45 -59
-98 -137 -98 -144 0 -5 -3 -11 -7 -13 -11 -4 -73 -85 -73 -94 0 -4 -11 -21
-24 -38 -35 -47 -156 -244 -156 -256 0 -5 -4 -10 -10 -10 -5 0 -10 -5 -10 -10
0 -6 -20 -43 -44 -83 -43 -71 -236 -449 -236 -462 0 -3 -18 -46 -39 -93 -61
-135 -141 -343 -141 -369 0 -13 -4 -23 -9 -23 -5 0 -12 -17 -16 -37 -4 -21
-10 -40 -14 -43 -18 -14 -143 -476 -191 -710 -43 -212 -80 -497 -101 -795 -12
-169 -7 -630 10 -830 12 -147 50 -443 72 -555 44 -233 73 -357 114 -500 19
-66 35 -126 35 -135 0 -8 4 -23 9 -33 6 -9 24 -62 41 -117 42 -133 113 -319
122 -323 5 -2 8 -12 8 -22 0 -17 33 -97 108 -265 61 -136 214 -431 269 -517
12 -21 23 -40 23 -43 0 -2 13 -24 29 -47 16 -24 82 -122 146 -218 141 -212
246 -358 287 -401 7 -8 25 -30 39 -49 15 -19 37 -46 49 -60 12 -14 40 -47 63
-73 168 -193 404 -429 577 -577 29 -25 62 -53 74 -64 12 -10 37 -30 56 -45 19
-14 41 -32 48 -39 8 -7 30 -25 50 -40 21 -15 39 -30 42 -33 14 -16 200 -149
209 -149 6 0 11 -4 11 -10 0 -5 13 -16 29 -25 16 -8 36 -21 43 -29 8 -8 44
-32 79 -54 35 -21 84 -53 109 -69 25 -17 68 -43 95 -58 28 -14 88 -49 135 -77
110 -65 358 -189 505 -253 17 -7 55 -24 85 -38 30 -13 93 -39 140 -56 47 -18
93 -36 102 -42 10 -5 25 -9 33 -9 8 0 23 -4 33 -9 30 -15 66 -30 92 -36 14 -4
88 -26 165 -50 133 -41 303 -87 425 -114 30 -7 73 -16 95 -21 138 -33 342 -64
615 -95 30 -3 66 -8 80 -11 67 -12 755 -16 900 -5 178 14 472 47 545 61 28 5
91 17 140 25 150 27 324 69 565 136 83 23 363 115 395 129 11 5 76 30 145 56
69 26 127 51 128 56 2 4 10 8 19 8 14 0 138 57 353 163 88 44 244 130 300 167
8 6 22 13 30 17 8 3 41 24 73 45 32 21 61 38 63 38 8 0 216 142 238 162 11 10
23 18 28 18 7 0 35 21 63 48 8 6 18 12 23 12 6 0 12 4 14 9 2 4 48 43 103 86
55 42 122 96 149 119 27 22 83 70 125 105 42 36 159 149 261 252 188 190 204
207 275 293 22 26 51 60 65 74 14 15 41 47 60 72 19 25 39 50 45 57 12 15 21
26 65 83 19 25 40 51 47 58 7 7 20 26 30 42 9 17 21 30 25 30 4 0 8 5 8 11 0
6 15 32 33 58 63 86 127 181 127 186 0 3 21 38 48 78 64 99 71 110 79 127 4 8
30 53 57 100 28 47 57 99 65 115 20 43 46 94 70 138 12 21 21 44 21 52 0 8 5
15 10 15 6 0 10 4 10 10 0 5 18 48 40 94 22 47 42 97 46 111 3 14 10 25 15 25
5 0 9 6 9 13 0 6 25 73 55 147 76 189 206 594 241 750 7 36 21 94 29 130 46
201 85 450 117 755 20 185 17 889 -5 1080 -35 314 -93 656 -146 855 -40 150
-73 264 -86 301 -9 24 -25 74 -35 110 -18 61 -26 85 -51 144 -4 11 -22 58 -40
105 -62 168 -93 245 -101 248 -4 2 -8 10 -8 18 0 8 -4 22 -9 32 -6 9 -53 105
-105 212 -94 193 -145 290 -157 300 -4 3 -16 24 -28 47 -24 47 -159 265 -203
328 -16 22 -40 58 -55 80 -44 68 -142 200 -170 230 -7 8 -13 16 -13 19 0 3 -9
16 -19 28 -63 75 -149 179 -166 200 -78 99 -339 366 -482 494 -141 127 -166
149 -187 164 -12 8 -30 23 -41 33 -11 10 -38 33 -59 50 -21 18 -53 44 -70 58
-57 47 -240 180 -326 236 -47 31 -105 70 -130 86 -85 57 -274 167 -287 167 -7
0 -13 4 -13 8 0 10 -392 208 -500 253 -208 86 -378 151 -407 156 -18 3 -33 10
-33 14 0 5 -7 9 -15 9 -8 0 -47 11 -87 24 -570 190 -1205 289 -1818 284 -129
-1 -255 -3 -280 -5z m610 -1393 c69 -6 148 -16 175 -22 28 -6 77 -14 110 -18
265 -33 633 -135 940 -262 152 -63 445 -207 445 -219 0 -5 6 -9 14 -9 8 0 44
-18 80 -40 36 -22 70 -40 76 -40 5 0 10 -4 10 -10 0 -5 6 -10 13 -10 6 0 28
-14 47 -30 19 -16 38 -30 42 -30 7 0 98 -59 108 -71 3 -3 27 -21 55 -40 27
-18 58 -41 70 -51 11 -9 54 -44 95 -78 41 -34 98 -80 125 -103 28 -22 123
-114 213 -204 135 -136 161 -167 152 -179 -11 -12 -117 -14 -739 -14 l-726 0
-80 46 c-162 93 -415 207 -540 245 -22 6 -56 17 -75 24 -19 7 -64 21 -100 30
-36 10 -76 21 -90 25 -41 13 -160 37 -245 50 -44 7 -118 19 -165 27 -112 19
-472 27 -630 15 -295 -24 -682 -107 -889 -191 -28 -12 -75 -31 -103 -42 -149
-60 -309 -140 -418 -207 l-35 -22 -732 0 c-402 0 -734 3 -736 8 -3 4 -1 16 3
27 10 25 197 215 310 315 46 41 91 81 98 88 8 7 30 25 50 40 21 15 39 29 42
33 43 49 445 324 555 379 17 8 32 17 35 20 3 3 28 16 55 30 28 14 52 27 55 30
10 10 309 150 320 150 4 0 34 12 66 26 208 93 612 203 894 244 167 25 212 31
285 39 118 12 621 13 765 1z m-2773 -2002 c6 -7 78 -146 161 -308 387 -760
501 -985 634 -1252 79 -158 149 -288 156 -288 8 0 12 16 13 48 0 26 1 440 2
920 2 763 4 874 17 882 16 11 1077 14 1104 4 15 -6 16 -210 16 -2214 0 -2004
-1 -2208 -16 -2214 -9 -3 -260 -6 -558 -6 -419 0 -546 3 -558 13 -16 12 -84
144 -563 1092 -141 281 -328 650 -415 820 -261 512 -570 1124 -622 1228 -26
53 -52 97 -57 97 -17 0 -29 -27 -60 -135 -35 -120 -69 -286 -97 -465 -27 -167
-27 -681 -1 -860 93 -634 336 -1185 732 -1658 50 -61 84 -110 81 -118 -8 -20
-1133 -21 -1158 -1 -31 25 -198 331 -271 497 -188 425 -309 901 -347 1355 -13
155 -13 556 0 710 23 275 61 478 160 850 12 44 69 212 92 270 67 168 83 206
110 265 96 208 232 453 262 472 6 4 273 8 592 8 454 0 584 -3 591 -12z m3810
-3 c11 -8 70 -113 131 -232 118 -235 398 -789 682 -1348 98 -192 291 -575 430
-850 322 -637 400 -788 411 -800 14 -14 31 9 45 58 73 271 83 313 109 486 54
347 43 766 -29 1131 -26 132 -59 250 -123 440 -61 181 -192 454 -297 620 -102
160 -183 271 -295 403 -60 70 -72 90 -60 98 8 5 160 9 338 9 289 0 333 -2 395
-19 39 -11 109 -24 156 -31 47 -6 116 -18 154 -26 38 -8 88 -14 113 -14 34 0
49 -6 66 -24 54 -58 237 -453 332 -716 14 -41 31 -87 37 -102 5 -14 14 -41 18
-60 5 -18 16 -58 24 -88 9 -30 32 -124 51 -208 150 -649 138 -1360 -31 -1995
-19 -73 -39 -148 -44 -167 -12 -45 -110 -315 -147 -405 -78 -184 -275 -557
-307 -577 -14 -10 -1168 -10 -1182 0 -6 4 -100 183 -209 397 -109 215 -268
530 -355 700 -86 171 -208 410 -270 533 -62 122 -116 222 -120 222 -4 0 -11
-7 -14 -16 -3 -9 -6 -422 -6 -918 0 -688 -3 -905 -12 -914 -9 -9 -147 -12
-560 -12 -531 0 -548 1 -558 19 -14 27 -14 4375 0 4402 10 18 27 19 558 19
467 0 551 -2 569 -15z m-3024 -5023 c65 -41 290 -152 372 -184 141 -56 204
-78 218 -78 9 0 18 -4 21 -9 3 -5 18 -11 33 -13 16 -3 64 -15 108 -28 126 -35
188 -48 360 -76 288 -47 780 -44 1035 6 25 5 65 12 90 15 25 4 59 10 75 15 17
5 59 16 95 26 151 39 214 58 315 96 191 72 292 119 480 225 l90 50 723 0 c503
0 727 -4 738 -11 12 -9 9 -18 -23 -53 -126 -139 -351 -353 -433 -413 -18 -14
-48 -37 -64 -51 -48 -40 -185 -142 -214 -159 -27 -16 -44 -28 -65 -46 -86 -74
-686 -394 -739 -394 -8 0 -18 -4 -23 -9 -6 -5 -38 -19 -72 -30 -33 -11 -74
-26 -90 -32 -55 -22 -69 -27 -173 -59 -284 -88 -495 -131 -775 -160 -49 -5
-135 -14 -190 -19 -131 -14 -503 -14 -599 -1 -41 6 -117 15 -168 21 -210 21
-295 35 -393 61 -22 6 -58 15 -80 19 -45 9 -142 33 -195 48 -128 37 -172 51
-225 70 -33 13 -78 29 -100 36 -59 19 -222 89 -360 154 -111 53 -412 219 -439
242 -6 6 -45 33 -87 62 -152 104 -204 141 -209 147 -3 3 -25 21 -50 40 -104
80 -266 228 -412 375 -109 109 -135 148 -107 157 6 2 338 3 737 3 l727 0 68
-43z m-1677 -424 c141 -151 144 -156 139 -196 -14 -100 -47 -313 -55 -362 -13
-73 -40 -260 -40 -278 0 -15 16 -2 203 172 123 114 133 127 117 157 -21 37 34
78 63 46 7 -7 38 -40 70 -74 31 -34 57 -66 57 -72 0 -6 -11 -18 -24 -26 -20
-13 -29 -14 -46 -5 -29 16 -52 0 -202 -141 -67 -63 -152 -142 -188 -175 -151
-139 -215 -199 -218 -206 -15 -34 -131 76 -132 125 0 15 16 125 35 245 66 412
81 521 71 514 -6 -3 -42 -36 -81 -72 -38 -36 -97 -91 -129 -121 -151 -137
-169 -158 -162 -191 6 -30 -14 -68 -35 -68 -8 0 -142 137 -148 151 -1 6 5 19
14 29 14 15 26 18 59 14 39 -5 44 -2 101 53 33 32 116 110 185 173 69 63 158
147 198 186 69 67 72 72 62 99 -9 22 -7 31 6 46 25 27 36 24 80 -23z m6901
-273 c17 -8 35 -15 39 -15 5 0 116 -40 249 -89 375 -140 401 -148 425 -133 11
6 28 12 38 12 20 0 50 -45 38 -57 -43 -43 -242 -257 -244 -264 -6 -14 -34 -10
-53 7 -13 11 -16 25 -12 45 4 24 0 31 -28 48 -19 12 -53 28 -77 37 l-42 17
-83 -88 c-117 -126 -112 -115 -81 -177 38 -77 52 -90 87 -82 27 6 67 -16 67
-36 0 -4 -38 -48 -84 -96 -82 -88 -84 -89 -104 -71 -17 16 -20 27 -15 71 5 53
4 56 -152 362 -86 170 -165 318 -176 329 -16 16 -23 18 -38 8 -15 -9 -23 -8
-40 3 -38 27 -31 39 116 191 l31 33 54 -20 c29 -12 67 -27 85 -35z m-523 -377
c6 -7 49 -55 95 -107 l83 -95 -24 -18 c-31 -23 -33 -23 -83 13 -54 39 -126 50
-171 25 -60 -32 -60 -30 -25 -86 18 -29 37 -57 42 -63 6 -7 25 -33 42 -58 18
-26 79 -116 137 -200 131 -191 151 -213 188 -205 23 6 30 2 45 -23 l17 -29
-53 -33 c-28 -18 -54 -36 -57 -39 -16 -18 -201 -140 -213 -140 -20 0 -37 42
-25 64 15 27 3 70 -34 119 -18 24 -71 99 -118 167 -118 171 -195 281 -215 308
l-16 22 -31 -21 c-63 -45 -85 -127 -57 -208 9 -27 8 -35 -6 -51 -29 -33 -50
-25 -73 28 -12 26 -38 77 -57 114 -22 39 -34 75 -31 85 3 10 32 35 63 55 64
42 152 102 168 115 9 8 180 127 329 230 71 49 64 46 80 31z m-5382 -263 c87
-58 98 -69 98 -95 0 -27 -51 -296 -121 -640 -33 -166 -36 -206 -13 -239 14
-19 14 -27 4 -47 -8 -13 -19 -24 -27 -24 -7 0 -77 42 -155 94 -199 132 -182
116 -168 148 10 21 21 28 48 30 33 3 37 7 48 43 6 23 15 59 18 81 l7 39 -83
55 c-46 30 -89 60 -96 67 -18 18 -58 27 -74 16 -8 -4 -35 -24 -61 -43 -47 -35
-51 -47 -33 -91 4 -9 -1 -25 -12 -39 l-19 -24 -102 68 c-55 36 -101 70 -101
74 0 4 7 18 16 30 11 16 21 21 40 16 39 -10 67 2 157 68 48 35 118 86 157 113
39 28 79 57 89 65 11 8 69 51 130 95 119 86 143 114 121 140 -11 13 -11 20 0
40 7 14 18 25 24 25 5 0 54 -29 108 -65z m4443 -432 c44 -42 148 -142 230
-222 307 -297 323 -311 360 -311 24 0 38 -6 45 -19 16 -31 12 -40 -22 -55 -18
-8 -100 -44 -183 -80 -137 -60 -151 -65 -162 -49 -16 20 -17 49 -3 58 23 14 8
52 -42 102 -29 29 -56 53 -61 53 -4 0 -32 -11 -60 -24 -29 -13 -77 -34 -107
-46 -83 -35 -82 -34 -78 -118 3 -57 8 -77 22 -87 10 -8 24 -11 31 -9 8 4 20
-7 29 -24 19 -36 16 -40 -74 -75 -36 -14 -79 -33 -95 -41 -41 -22 -50 -20 -64
10 -10 23 -8 30 15 57 l27 32 -6 135 c-29 628 -29 630 -77 630 -26 0 -48 44
-32 63 6 7 32 20 59 30 26 9 70 28 97 42 27 14 54 25 60 25 6 0 47 -35 91 -77z
m-3710 19 l109 -40 111 -325 c60 -179 113 -328 116 -331 6 -6 149 391 149 415
0 9 -9 24 -19 33 -16 15 -18 23 -9 47 12 34 7 34 136 -13 50 -19 82 -36 82
-45 0 -25 -20 -52 -34 -47 -38 14 -67 -56 -292 -690 -27 -78 -27 -78 -59 -73
-18 3 -55 15 -82 27 l-49 21 -124 369 c-67 204 -126 370 -129 370 -6 0 -56
-132 -142 -375 -32 -90 -36 -130 -14 -139 16 -6 20 -43 6 -65 -7 -12 -26 -8
-105 19 -53 18 -101 38 -106 45 -15 18 12 60 42 63 33 4 40 13 66 87 25 70 79
219 153 425 55 153 59 178 34 196 -16 12 -19 32 -9 59 9 22 30 18 169 -33z
m2866 -283 c185 -55 309 -227 309 -429 0 -132 -43 -234 -134 -317 -94 -86
-191 -123 -442 -168 -78 -14 -156 -30 -175 -35 -55 -16 -67 -13 -74 21 -6 25
-3 34 14 45 15 11 21 26 21 52 0 53 -117 647 -132 671 -7 12 -20 21 -28 21
-23 0 -40 20 -40 47 0 13 1 23 3 23 3 0 393 75 437 83 41 9 195 -1 241 -14z
m-1793 -25 l172 -18 0 -33 c0 -23 -5 -33 -16 -33 -44 0 -52 -37 -89 -387 -15
-147 -16 -166 -1 -215 17 -58 55 -107 99 -126 15 -7 55 -12 87 -12 54 0 64 3
99 35 68 62 71 75 116 479 8 77 13 147 10 157 -3 9 -16 24 -30 33 -19 13 -22
20 -16 43 4 15 14 28 22 28 48 0 199 -18 203 -23 11 -18 -8 -64 -29 -72 -13
-5 -26 -14 -30 -21 -4 -6 -20 -125 -36 -263 -33 -299 -46 -345 -123 -422 -66
-66 -138 -94 -240 -94 -215 0 -392 136 -412 317 -3 26 6 156 20 287 15 131 24
247 21 257 -3 9 -17 24 -30 33 -14 9 -22 23 -20 31 3 8 7 22 10 31 3 9 12 13
22 11 10 -3 96 -13 191 -23z"/>
<path d="M9940 2796 c0 -6 109 -228 115 -234 2 -2 8 3 14 10 6 7 33 38 61 68
27 30 50 58 50 62 0 3 -21 14 -47 24 -41 15 -139 54 -180 71 -7 3 -13 2 -13
-1z"/>
<path d="M3843 2162 c-10 -10 -53 -43 -95 -73 -43 -29 -78 -57 -78 -60 0 -6
152 -111 155 -107 4 4 55 235 55 248 0 16 -17 12 -37 -8z"/>
<path d="M8401 1604 c4 -69 9 -127 11 -129 8 -9 163 62 163 74 0 12 -163 181
-174 181 -3 0 -3 -57 0 -126z"/>
<path d="M7348 1613 c-23 -6 -23 -5 11 -174 16 -79 39 -193 51 -254 11 -60 28
-143 36 -183 l16 -74 36 7 c77 14 128 56 165 135 17 37 21 64 21 145 -1 185
-78 343 -191 391 -31 13 -108 17 -145 7z"/>
</g>
</svg>





    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
